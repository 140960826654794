import appsetting from "../appsetting";

import * as userUtils from "@/store/userUtils";

//const axios = require("axios").default;
import axios from "axios";

const axiosGet = (url, params = null, headers = null) => {
  return axios.get(url, { params, headers: appKeyToHeaders(headers) });
};

const axiosPost = (url, params = null, headers = null) => {
  return axios.post(url, params, { headers: appKeyToHeaders(headers) });
};

const axiosDelete = (url, params = null, headers = null) => {
  return axios.delete(url, { params, headers: appKeyToHeaders(headers) });
};

const appKeyToHeaders = (headers) => {
  if (headers == null) {
    headers = {};
  }

  headers.ApiKey = appsetting.backendAPI.apiKey;
  headers.Password = appsetting.backendAPI.password;

  // ใช้สำหรับทำแจ้งเตือน ในกรณีที่ไม่สามารถระบุ permission role ได้จาก user ที่มี role มากกว่า 1
  const currentPermission = userUtils.getCurrentPermissionGroupId();
  console.log("axiosUtils currentPermission", currentPermission);
  if (
    currentPermission !== null &&
    currentPermission !== undefined &&
    currentPermission !== ""
  ) {
    headers.Permission = userUtils.getCurrentPermissionGroupId();
  }

  const currentDepartment = userUtils.getCurrentDepartmentId();
  console.log("axiosUtils currentDepartment", currentDepartment);
  if (
    currentDepartment !== null &&
    currentDepartment !== undefined &&
    currentDepartment !== ""
  ) {
    headers.Department = userUtils.getCurrentDepartmentId();
  }

  // now try to add token
  headers.Authorization = userUtils.getCurrentToken();

  return headers;
};

const axiosDownloadFilePost = (
  url,
  filename = "export",
  extension = ".pdf",
  params = null,
  headers = null
) => {
  //return axios.post(url, params, { headers: appKeyToHeaders(headers) });
  axios({
    url: url,
    method: "POST",
    headers: headers,
    data: params,
    responseType: "blob", // important
  }).then((response) => {
    // console.log("axiosDownloadFilePost response", response);
    const aurl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = aurl;
    link.setAttribute("download", filename + extension);
    document.body.appendChild(link);
    link.click();
  });
};

const axiosDownloadFileGet = (
  url,
  filename = "export",
  extension = ".pdf",
  params = null,
  headers = null
) => {
  //return axios.post(url, params, { headers: appKeyToHeaders(headers) });
  axios({
    url: url,
    method: "Get",
    headers: headers,
    data: params,
    responseType: "blob", // important
  }).then((response) => {
    const aurl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = aurl;
    link.setAttribute("download", filename + extension);
    document.body.appendChild(link);
    link.click();
  });
};
export {
  appKeyToHeaders,
  axiosGet,
  axiosPost,
  axiosDelete,
  axiosDownloadFilePost,
  axiosDownloadFileGet,
};
